import React, { memo, useCallback } from 'react'
import { TextInput } from '../../Atoms/Inputs/Text'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { handleError } from '../../../utils/handleErrors'
import { Header } from './header'
import { updateEntity } from '../../../store/common/api'
import { SinglePageHook } from '../../../hooks/single-page-hook'
import { SegmentCondition } from './segment-condition'
import { v4 } from 'uuid'

const entityType = 'segment'
const entityURL = 'segments'

export const Segment = memo(() => {
    const { id, entity, setEntity, versions, setVersions } = SinglePageHook({
        entityType,
    })

    const onUpdateSegment = useCallback(() => {
        const { name, property, modelId, conditions } = entity
        updateEntity(id, entityType, { name, property, conditions, modelId })
            .then((updatedEntity) => {
                setEntity(updatedEntity)
                setVersions((prevState) => {
                    return [updatedEntity, ...prevState]
                })
                toast.success('Successfully Updated')
            })
            .catch(handleError)
    }, [entity])

    const addFormItem = () => {
        const defaultCondition = {
            id: v4(),
            name: '',
            condition: 'isEqual',
            value: '',
        }
        setEntity((prevState) => {
            return {
                ...prevState,
                conditions: [...prevState.conditions, defaultCondition],
            }
        })
    }

    const removeSegmentItem = (id) => {
        setEntity((prevState) => {
            return {
                ...prevState,
                conditions: prevState.conditions.filter(
                    (item) => id !== item.id,
                ),
            }
        })
    }

    const updateSegmentItem = (id, property, value) => {
        setEntity((prevState) => {
            return {
                ...prevState,
                conditions: prevState.conditions.map((c) => {
                    if (id === c.id) {
                        c[property] = value
                    }
                    return c
                }),
            }
        })
    }

    const propertyChange = (e) => {
        setEntity((prevState) => {
            return {
                ...prevState,
                property: e.target.value,
            }
        })
    }
    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="segment-content">
                        <div className="formHeader">
                            <TextInput
                                label="Key"
                                placeholder="Key Value"
                                value={entity.property}
                                onChange={propertyChange}
                            />
                        </div>

                        <div className="formBody">
                            <div className="formBodyLabels">
                                <div>Name</div>
                                <div>Condition</div>
                                <div>Value</div>
                            </div>
                            <div className="formWrapper">
                                {(entity.conditions || []).map((item) => {
                                    return (
                                        <SegmentCondition
                                            key={item.id}
                                            condition={item}
                                            removeSegmentItem={
                                                removeSegmentItem
                                            }
                                            updateSegmentItem={
                                                updateSegmentItem
                                            }
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="addFormItem">
                            <img
                                onClick={addFormItem}
                                alt="add"
                                src="/assets/images/addFormItem.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
