import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_ru from './translations/ru/common.json'
import common_en from './translations/en/common.json'
import { Provider } from 'react-redux'
import store from './store'

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'ru',
    resources: {
        en: {
            common: common_en,
        },
        ru: {
            common: common_ru,
        },
    },
})

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <App />
        </Provider>
    </I18nextProvider>,
    document.getElementById('root'),
)
