import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeParameter(response, dataModelsForNormalize) {
    if (response?.parameters?.length) {
        const data = response.parameters.map((parameter) => {
            return {
                ...parameter.models[0],
                user: Users().getUserById(parameter.models?.[0].userId)?.name,
                dataModel: dataModelsForNormalize.find(
                    (dm) => dm._id === parameter.modelId,
                )?.name,
                _id: parameter._id,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    } else {
        return []
    }
}

export function getAllParameters(dataModelsForNormalize) {
    const page = 1
    const limit = 10000
    return Api()
        .get(`api/parameters/all?page=${page}&limit=${limit}`)
        .then((response) => {
            const data = normalizeParameter(response, dataModelsForNormalize)
            return data
        })
}

export function getParameterById({ id }) {
    return Api().get(`api/parameters/${id}`)
}

export function createParameter(entity, dataModelsForNormalize) {
    return Api()
        .post(`api/parameters/create`, entity)
        .then((response) =>
            normalizeParameter(response, dataModelsForNormalize),
        )
}

export function parameterUpdate({
    name,
    parameterId,
    modelId,
    key = null,
    type = 1,
    selectedType = null,
    validationParameters = null,
    outParameters = null,
    group,
    defaultValue = null,
    description,
}) {
    return Api().put(`api/parameters/update/${parameterId}`, {
        name,
        modelId,
        key,
        group,
        defaultValue,
        description,
        type,
        selectedType,
        validationParameters,
        outParameters,
    })
}

export function archiveParameterById(id) {
    return Api().patch(`api/parameters/archive/${id}`)
}

export function getParametersByDataModelId(
    { page = 0, limit = 1000, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .get(`api/parameters/data-model/${modelId}?page=${page}&limit=${limit}`)
        .then((response) => {
            const data = normalizeParameter(response, dataModelsForNormalize)

            return data
        })
}
