import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeSegments(response, dataModelsForNormalize) {
    if (response?.segments?.length) {
        const data = response?.segments?.map((item) => {
            return {
                ...item.models[0],
                dataModelName: dataModelsForNormalize.find(
                    (dm) => dm._id === item.models[0].modelId,
                )?.name,
                _id: item._id,
                user: Users().getUserById(item.models[0].userId)?.name,
            }
        })
        return data.sort(
            (a, b) => Date.parse(a.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}
function normalizeSegment(response, dataModelsForNormalize) {
    if (response.segment) {
        return {
            ...response.segment,
            _id: response.segment.segmentId,
            dataModelName: dataModelsForNormalize.find(
                (dm) => dm._id === response.segment.modelId,
            )?.name,
            user: Users().getUserById(response.segment.userId)?.name,
        }
    }
    return []
}

export function getAllSegments(dataModelsForNormalize) {
    const page = 1
    const limit = 1000
    return Api()
        .get(`api/segments/all?page=${page}&limit=${limit}`)
        .then((response) => normalizeSegments(response, dataModelsForNormalize))
}

export function createSegment(
    { name, property, conditions, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .post('api/segments/create', {
            name,
            property,
            conditions,
            modelId,
        })
        .then((response) => response)
}

export function getSegmentByDataModelId(
    { page = 0, limit = 1000, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .get(`api/segments/data-model/${modelId}?page=${page}&limit=${limit}`)
        .then((response) => {
            return normalizeSegments(response, dataModelsForNormalize)
        })
}

export function getSegmentById({ id }) {
    return Api().get(`api/segments/${id}`)
}
