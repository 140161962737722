import React, { memo, useState } from 'react'
import { TextInput } from '../../Atoms/Inputs/Text'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { SegmentCondition } from './segment-condition'
import { v4 } from 'uuid'

const entityURL = 'segments'
const entityType = 'segment'

export const CreateSegment = memo(() => {
    const [entity, setEntity] = useState({
        name: '',
        property: '',
        conditions: [],
    })
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }

    const addFormItem = () => {
        const defaultCondition = {
            id: v4(),
            name: '',
            condition: 'isEqual',
            value: '',
        }
        setEntity((prevState) => {
            return {
                ...prevState,
                conditions: [...prevState.conditions, defaultCondition],
            }
        })
    }

    const removeSegmentItem = (id) => {
        setEntity((prevState) => {
            return {
                ...prevState,
                conditions: prevState.conditions.filter(
                    (item) => id !== item.id,
                ),
            }
        })
    }

    const updateSegmentItem = (id, property, value) => {
        setEntity((prevState) => {
            return {
                ...prevState,
                conditions: prevState.conditions.map((c) => {
                    if (id === c.id) {
                        c[property] = value
                    }
                    return c
                }),
            }
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header
                        entity={entity}
                        entityURL={entityURL}
                        entityType={entityType}
                    />
                    <div className="segment-content">
                        <div className="formHeader">
                            <TextInput
                                label="Name"
                                placeholder="Name"
                                value={entity.name}
                                onChange={({ target }) =>
                                    onEntityChange('name', target.value)
                                }
                            />
                        </div>
                        <div className="formHeader">
                            <TextInput
                                label="Key"
                                placeholder="Key Value"
                                value={entity.property}
                                onChange={({ target }) =>
                                    onEntityChange('property', target.value)
                                }
                            />
                        </div>

                        <div className="formBody">
                            <div className="formBodyLabels">
                                <div>Name</div>
                                <div>Condition</div>
                                <div>Value</div>
                            </div>
                            <div className="formWrapper">
                                {(entity.conditions || []).map((item) => {
                                    return (
                                        <SegmentCondition
                                            key={item.id}
                                            condition={item}
                                            removeSegmentItem={
                                                removeSegmentItem
                                            }
                                            updateSegmentItem={
                                                updateSegmentItem
                                            }
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="addFormItem">
                            <img
                                onClick={addFormItem}
                                alt="add"
                                src="/assets/images/addFormItem.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
