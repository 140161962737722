import React, { useState } from 'react'
import '../../Organisms/single-page-common/single-page-content-header.scss'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { createScript } from '../../../store/script/api'
import { toast } from 'react-toastify'
import { handleError } from '../../../utils/handleErrors'
import { updateEntity } from '../../../store/common/api'
const entityType = 'script'

export const Header = ({ entity, entityURL = 'scripts' }) => {
    const history = useHistory()
    const params = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const type = searchParams.get('type')
    const [archivePopup, setArchivePopup] = useState(false)
    const cancel = () => {
        history.push(`/${entityURL}`)
        setArchivePopup(false)
    }

    const save = () => {
        createScript({ ...entity, modelId: params.modelId })
            .then(() => {
                history.push(`/${entityURL}`)
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }
    const edit = () => {
        updateEntity(params.id, entityType, {
            name: entity.name,
            body: entity.body,
            modelId: entity.modelId,
        })
            .then(() => {
                toast.success('Successfully Updated')
            })
            .catch(handleError)
    }

    const handleSaveOrEdit = entity.id ? edit : save

    return (
        <>
            <ArchivePopup
                isOpen={archivePopup}
                togglePanel={setArchivePopup}
                onDelete={cancel}
                text="Are you sure you want to cancel"
            ></ArchivePopup>

            <div className="single-page-content-header">
                <div className="title">
                    <span>{entity.name}</span>
                </div>

                <div className="actions">
                    {!type ? (
                        <>
                            <ButtonWithIcon
                                onClick={() => setArchivePopup(true)}
                                icon="/assets/images/Delete.png"
                                red={true}
                                value="Cancel"
                            />
                            <ButtonWithIcon
                                onClick={() => handleSaveOrEdit()}
                                value="Save"
                                green={true}
                            />
                        </>
                    ) : (
                        <ButtonWithIcon
                            onClick={() => history.goBack()}
                            red={true}
                            value="Back"
                        />
                    )}
                </div>
            </div>
        </>
    )
}
