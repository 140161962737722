import React, { useCallback, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams } from 'react-router-dom'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import {
    getSegmentByDataModelId,
    getSegmentById,
} from '../../../store/segment/api'
import { handleError } from '../../../utils/handleErrors'
import { AddNewSegmentPopup } from '../../Organisms/Popups/AddNewSegmentPopup'
import { AddVariablePopup } from '../../Organisms/Popups/AddVariablePopup'
import { SegmentRemovePopup } from '../../Organisms/Popups/SegmentRemovePopup'
import { Segment } from './value-setter-segment'
import s from './ValueSetter.module.scss'
import { Header } from './header'
import { TextInput } from '../../Atoms/Inputs/Text'

const entityType = 'valueSetter'
const entityURL = 'value-setters'

export const CreateValueSetter = () => {
    console.log('CreateValueSetter')
    const params = useParams()
    const [segmentModelsList, setSegmentModelsList] = useState([])
    const [entity, setEntity] = useState({
        body: {
            variables: [],
            tree: [],
        },
    })
    const entityVariables = entity?.body?.variables || []
    let entityTree = entity?.body?.tree || null
    const setStateCurrentTree = (tree) => {
        setEntity({
            ...entity,
            body: {
                ...entity.body,
                tree,
            },
        })
    }
    const onEntityChange = (key, name) => {
        setEntity({
            ...entity,
            [key]: name,
        })
    }
    const updateEntityVariables = (variables) => {
        setEntity({
            ...entity,
            body: {
                ...entity.body,
                variables,
            },
        })
    }
    const removeEntityVariable = (id) => {
        updateEntityVariables(entityVariables?.filter((i) => i.id !== id))
    }
    const addNewVariable = () => {
        updateEntityVariables([
            ...entityVariables,
            {
                id: Math.random() + new Date().getTime(),
                name: variable,
                comment,
            },
        ])
        setShowVariablePopup(false)
    }

    const openAddVariablePopup = () => {
        setShowVariablePopup(true)
    }

    useEffect(() => {
        getSegmentByDataModelId(
            {
                page: 1,
                limit: 10000,
                modelId: params.modelId,
            },
            [],
        )
            .then((segments) => {
                const options = segments.map((segment) => {
                    return {
                        name: segment.name,
                        value: segment._id,
                    }
                })
                setSegmentModelsList(() => [
                    { name: 'Choose', value: '' },
                    ...options,
                ])
            })
            .catch(handleError)
    }, [])

    const [showVariablePopup, setShowVariablePopup] = useState(false)
    const [variable, setVariable] = useState()
    const [comment, setComment] = useState()
    const [showSegmentPopup, setShowSegmentPopup] = useState(false)
    const [selectedSegment, setSelectedSegment] = useState()
    const [segmentArchivePopup, setSegmentArchivePopup] = useState(false)

    const updateVariables = (
        nodes,
        treeId,
        conditionId,
        variableName,
        value,
    ) => {
        return nodes.map((node) => {
            if (node.treeId === treeId) {
                return {
                    ...node,
                    conditions: node.conditions.map((condit) => {
                        if (condit.id === conditionId) {
                            condit[variableName] = value
                            return { ...condit }
                        }
                        if (condit.children) {
                            return {
                                ...condit,
                                children: updateVariables(
                                    condit.children,
                                    treeId,
                                    conditionId,
                                    variableName,
                                    value,
                                ),
                            }
                        }
                        return condit
                    }),
                }
            }

            return {
                ...node,
                conditions: node.conditions.map((condition) => {
                    if (condition.children) {
                        return {
                            ...condition,
                            children: updateVariables(
                                condition.children,
                                treeId,
                                conditionId,
                                variableName,
                                value,
                            ),
                        }
                    }
                    return condition
                }),
            }
        })
    }

    const updateVariable = (treeId, conditionId, variableName, value) => {
        setStateCurrentTree(
            updateVariables(
                entityTree,
                treeId,
                conditionId,
                variableName,
                value,
            ),
        )
    }

    const addSegmentChild = (treeId, conditionId, name) => {
        setShowSegmentPopup({ treeId, conditionId, name })
    }

    const setToggleSegment = (nodes, treeId, conditionId, isOpen) => {
        return nodes.map((node) => {
            if (node.treeId === treeId) {
                return {
                    ...node,
                    conditions: node.conditions.map((condition) => {
                        if (condition.id === conditionId) {
                            return {
                                ...condition,
                                isOpen: isOpen,
                            }
                        }
                        if (condition.children) {
                            return {
                                ...condition,
                                children: setToggleSegment(
                                    condition.children,
                                    treeId,
                                    conditionId,
                                    isOpen,
                                ),
                            }
                        }
                        return condition
                    }),
                }
            }

            return {
                ...node,
                conditions: node.conditions.map((condition) => {
                    if (condition.children) {
                        return {
                            ...condition,
                            children: setToggleSegment(
                                condition.children,
                                treeId,
                                conditionId,
                                isOpen,
                            ),
                        }
                    }
                    return condition
                }),
            }
        })
    }

    const toggleRow = (treeId, conditionId, isOpen) => {
        const obj = setToggleSegment(entityTree, treeId, conditionId, isOpen)
        setStateCurrentTree(obj)
    }

    const removeChildSegment = (parent, removedEl) => {
        return parent?.map((node) => {
            if (node.treeId === removedEl.treeId) {
                return {
                    ...node,
                    conditions: node.conditions.map((condit) => {
                        if (condit.id === removedEl.conditionId) {
                            delete condit.children
                            return condit
                        }
                        if (condit.children) {
                            return {
                                ...condit,
                                children: removeChildSegment(
                                    condit.children,
                                    segmentArchivePopup,
                                ),
                            }
                        }
                        return condit
                    }),
                }
            }

            return {
                ...node,
                conditions: node.conditions.map((condition) => {
                    if (condition.children) {
                        return {
                            ...condition,
                            children: removeChildSegment(
                                condition.children,
                                segmentArchivePopup,
                            ),
                        }
                    }
                    return condition
                }),
            }
        })
    }

    const onArchiveSegment = () => {
        setStateCurrentTree(removeChildSegment(entityTree, segmentArchivePopup))
        setSegmentArchivePopup(false)
    }

    const setChildSegment = (nodes, level, segment) => {
        return nodes.map((node) => {
            if (node.treeId === showSegmentPopup.treeId) {
                return {
                    ...node,
                    level,
                    conditions: node.conditions.map((condition) => {
                        if (condition.id === showSegmentPopup.conditionId) {
                            return {
                                ...condition,
                                isOpen: true,
                                level,
                                children: [
                                    {
                                        ...segment,
                                        level: level + 1,
                                        treeId:
                                            Math.random() +
                                            new Date().getTime(),
                                    },
                                ],
                            }
                        }
                        if (condition.children) {
                            return {
                                ...condition,
                                level,
                                children: setChildSegment(
                                    condition.children,
                                    level + 1,
                                    segment,
                                ),
                            }
                        }
                        return {
                            ...condition,
                            level,
                        }
                    }),
                }
            }

            return {
                ...node,
                level,
                conditions: node.conditions.map((condition) => {
                    if (condition.children) {
                        return {
                            ...condition,
                            level,
                            children: setChildSegment(
                                condition.children,
                                level + 1,
                                segment,
                            ),
                        }
                    }
                    return {
                        ...condition,
                        level,
                    }
                }),
            }
        })
    }

    const addSegment = useCallback(() => {
        getSegmentById({ id: selectedSegment })
            .then(({ segment }) => {
                const { segmentId, name, conditions } = segment
                const newSegment = { segmentId, name, conditions }
                const newTree = setChildSegment(entityTree, 1, newSegment)
                setStateCurrentTree(newTree)
                setShowSegmentPopup(false)
            })
            .catch(handleError)
    }, [showSegmentPopup, selectedSegment])

    const normalizeMainSegment = (action) => {
        return {
            ...action,
            name: entity?.name,
            body: {
                variables: [...entity.body.variables],
                tree: [
                    {
                        main: true,
                        name: action?.name,
                        conditions: action?.conditions,
                        segmentId: action?.segmentId,
                        treeId: Math.random() + new Date().getTime(),
                    },
                ],
            },
        }
    }

    const onChangeSegment = (id) => {
        if (id) {
            getSegmentById({ id })
                .then(({ segment }) => {
                    const normSeg = normalizeMainSegment(segment)
                    setEntity(normSeg)
                })
                .catch(handleError)
        }
    }

    return (
        <>
            <ToastContainer />
            {segmentArchivePopup && (
                <SegmentRemovePopup
                    setSegmentArchivePopup={setSegmentArchivePopup}
                    segmentName={segmentArchivePopup.treeId}
                    onArchiveSegment={onArchiveSegment}
                />
            )}
            {showSegmentPopup && (
                <AddNewSegmentPopup
                    setShowSegmentPopup={setShowSegmentPopup}
                    segmentList={segmentModelsList}
                    addSegment={addSegment}
                    setSelectedSegment={setSelectedSegment}
                    selectedSegment={selectedSegment}
                />
            )}

            {showVariablePopup && (
                <AddVariablePopup
                    variable={variable}
                    setVariable={setVariable}
                    comment={comment}
                    setComment={setComment}
                    setShowVariablePopup={setShowVariablePopup}
                    addVariable={addNewVariable}
                />
            )}
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="value-setter-content">
                        <div className="formHeaderActions">
                            <div className="formHeaderSelect">
                                <div className={s.formHeader}>
                                    <TextInput
                                        label="Name"
                                        placeholder="Name"
                                        value={entity.name}
                                        onChange={({ target }) =>
                                            onEntityChange('name', target.value)
                                        }
                                    />
                                </div>
                                {segmentModelsList?.length && (
                                    <SelectWithLabelInput
                                        label="Select Segment"
                                        options={segmentModelsList}
                                        defaultValue={
                                            entityTree?.length
                                                ? entityTree[0]?.segmentId
                                                : ''
                                        }
                                        onChange={({ target }) => {
                                            onChangeSegment(target.value)
                                        }}
                                    />
                                )}
                            </div>
                            <div className="formHeaderButton">
                                <ButtonWithIcon
                                    onClick={openAddVariablePopup}
                                    icon="/assets/images/icon+.png"
                                    transparent={true}
                                    value="Add Variable"
                                />
                            </div>
                        </div>
                        <Segment
                            stateLevelLength={0}
                            updateVariable={updateVariable}
                            stateCurrentTree={
                                entityTree?.length ? entityTree[0] : null
                            }
                            stateCurrentVariables={entityVariables}
                            removeVariable={removeEntityVariable}
                            addSegmentChild={addSegmentChild}
                            toggleRow={toggleRow}
                            setSegmentArchivePopup={setSegmentArchivePopup}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
