import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

const normalizeScripts = (response, dataModelsForNormalize) => {
    if (response?.scripts?.length) {
        const data = response?.scripts.map((item) => {
            return {
                ...item.models[0],
                dataModelName: dataModelsForNormalize.find(
                    (dm) => dm._id === item.models[0].modelId,
                )?.name,
                _id: item._id,
                createdBy: Users().getUserById(item.models[0].userId)?.name,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

export function getAllScripts(dataModelsForNormalize) {
    const page = 1
    const limit = 1000
    return Api()
        .get(`api/scripts/all?page=${page}&limit=${limit}`)
        .then((response) => normalizeScripts(response, dataModelsForNormalize))
}

export function createScript({ name, body, modelId }, dataModelsForNormalize) {
    return Api()
        .post('api/scripts/create', {
            name,
            body,
            modelId,
        })
        .then((response) => {
            return response
        })
}

export function getScriptsByDataModelId(
    { page = 1, limit = 1000, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .get(`api/scripts/data-model/${modelId}?page=${page}&limit=${limit}`)
        .then((response) => {
            const data = normalizeScripts(response, dataModelsForNormalize)
            return data
        })
}
