import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeValues(response, dataModelsForNormalize) {
    if (response?.['value-setters']?.length) {
        const data = response?.['value-setters'].map((item) => {
            return {
                ...item.models[0],
                dataModelName: dataModelsForNormalize.find(
                    (dm) => dm._id === item.models[0].modelId,
                )?.name,
                _id: item._id,
                user: Users().getUserById(item.models[0].userId)?.name,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

const normalizeValueSetter = (VSetter, dataModelsForNormalize) => {
    const { userId, clientId, body, createdAt, modelId, name, valueSetterId } =
        VSetter
    return {
        dataModelName: dataModelsForNormalize.find((dm) => dm.value === modelId)
            ?.name,
        user: Users().getUserById(userId)?.name,
        clientId,
        body,
        createdAt,
        _id: valueSetterId,
        name,
    }
}

export function getAllValueSetter(dataModelsForNormalize) {
    const page = 1
    const limit = 100000
    return Api()
        .get(`api/value-setters/all?page=${page}&limit=${limit}`)
        .then((response) => normalizeValues(response, dataModelsForNormalize))
}

export function getAllValueSettersByModelId(
    { page = 1, limit = 1000, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .get(
            `api/value-setters/data-model/${modelId}?page=${page}&limit=${limit}`,
        )
        .then((response) => {
            return normalizeValues(response, dataModelsForNormalize)
        })
}
export function createValueSetter(valueSetter, dataModelsForNormalize) {
    return Api()
        .post(`api/value-setters/create`, valueSetter)
        .then((response) => response.valueSetter)
}
