import React, { memo, useEffect, useState } from 'react'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { TextInput } from '../../Atoms/Inputs/Text'
import {
    getSegmentByDataModelId,
    getSegmentById,
} from '../../../store/segment/api'
import { scoringsUpdate } from '../../../store/score/api'
import { handleError } from '../../../utils/handleErrors'
import { ScoreResults } from './score-results'
import { Header } from './header'
import { ScoreSegments } from './score-segments'
import { useParams } from 'react-router-dom'

const entityURL = 'scorings'
export const CreateScore = memo(() => {
    const params = useParams()
    const [entity, setEntity] = useState({
        name: '',
        initialScore: '',
        propertyValue: '',
        propertyCode: '',
        propertyResult: '',
        segments: [],
        results: [],
    })
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }
    const [segmentModelsList, setSegmentModelsList] = useState()

    useEffect(() => {
        getSegmentByDataModelId(
            {
                page: 1,
                limit: 10000,
                modelId: params.modelId,
            },
            [],
        )
            .then((segments) => {
                const options = segments.map((segment) => {
                    return {
                        name: segment.name,
                        value: segment._id,
                    }
                })
                console.log(options)

                setSegmentModelsList([
                    { name: 'Choose', value: '' },
                    ...options,
                ])
            })
            .catch(handleError)
    }, [])

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />

                    <div className="score-content">
                        <div className="mainForm">
                            <div className="mainFormContent">
                                <div className="formItem">
                                    <TextInput
                                        label="Name"
                                        placeholder="Name"
                                        value={entity.name}
                                        onChange={({ target }) => {
                                            onEntityChange('name', target.value)
                                        }}
                                    />
                                </div>
                                <div className="formItem">
                                    <TextInput
                                        label="Initial Score"
                                        placeholder="Initial Score"
                                        value={entity.initialScore}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'initialScore',
                                                target.value,
                                            )
                                        }}
                                    />
                                </div>

                                <div className="formItem">
                                    <TextInput
                                        label="Score Result Value Variable"
                                        placeholder="Score Result Value Variable"
                                        value={entity.propertyValue}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'propertyValue',
                                                target.value,
                                            )
                                        }}
                                    />
                                </div>

                                <div className="formItem">
                                    <TextInput
                                        label="Score Result Code Variable"
                                        placeholder="Score Result Code Variable"
                                        value={entity.propertyCode}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'propertyCode',
                                                target.value,
                                            )
                                        }}
                                    />
                                </div>

                                <div className="formItem">
                                    <TextInput
                                        label="Score Value Variable"
                                        placeholder="Score Value Variable"
                                        value={entity.propertyResult}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'propertyResult',
                                                target.value,
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="formSegment">
                            <ScoreSegments
                                entity={entity}
                                setEntity={setEntity}
                                segmentModelsList={segmentModelsList}
                            />
                        </div>
                        <ScoreResults setEntity={setEntity} entity={entity} />
                    </div>
                </div>
            </div>
        </>
    )
})
