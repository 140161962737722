import React, { useCallback, useEffect, useState } from 'react'
import s from './ScoreList.module.scss'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory } from 'react-router-dom'
import { SidebarBlack } from '../../Organisms/SidebarBlack'
import { HeaderBlack } from '../../Organisms/HeaderBlack'
import { CreateNewScorePopup } from '../../Organisms/Popups/CreateNewScorePopup'
import Table from '../../Organisms/Tables/Table/Table'
import { scoreTableColumn } from '../../../utils/tableColumns'
import {
    createScorings,
    getAllScorings,
    getAllScoringsByModelId,
} from '../../../store/score/api'
import { getDataModel } from '../../../store/model/api'
import { handleError } from '../../../utils/handleErrors'
import ListPage from '../../Organisms/ListPage/ListPage'
import { archiveEntityById } from '../../../store/general'
import { formatDate } from '../../../Services/dateFormat'
import editGreen from '../../../imgs/EditGreen.png'
import Delete from '../../../imgs/recycle.png'
import View from '../../../imgs/view.png'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { getSegmentByDataModelId } from '../../../store/segment/api'
import { FlowDetail } from '../FlowList/FlowDetail'
import { SegmentListHeader } from '../SegmentList/SegmentListHeader'
import { ScoreListHeader } from './ScoreListHeader'

const type = 'scoring'
export const ScoreList = () => {
    const [archivePopupId, setArchivePopupId] = useState(null)
    const [triggerRefresh, setTriggerRefresh] = useState(0)
    const [dataModel, setDataModel] = useState()
    const [dataModels, setDataModels] = useState([])
    const history = useHistory()

    useEffect(() => {
        getDataModel().then((_dataModels) => {
            setDataModels(_dataModels)
            setDataModel(_dataModels?.[0]?._id)
            setTriggerRefresh((v) => ++v)
        })
    }, [])
    const viewEntity = (item) => {
        history.push(`${window.location.pathname}/${item.data._id}?type=view`)
    }
    const deleteEntity = () => {
        archiveEntityById(archivePopupId, type)
            .then(() => {
                setArchivePopupId(null)
                setTriggerRefresh((v) => ++v)
                toast.success('Segment deleted successfully')
            })
            .catch(handleError)
    }

    const segmentTableColumn = [
        { headerName: 'Name', field: 'name', filter: true },
        { headerName: 'Data Model', field: 'dataModelName', filter: true },
        {
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            headerName: 'Created By',
            field: 'user',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            alt={'edit'}
                            src={editGreen}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            alt={'Delete'}
                            src={Delete}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setArchivePopupId(params.data._id)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={View}
                            alt={'View'}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewEntity(params)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                )
            },
        },
    ]
    return (
        <>
            <ArchivePopup
                isOpen={!!archivePopupId}
                togglePanel={setArchivePopupId}
                onDelete={() => deleteEntity()}
            ></ArchivePopup>
            <div className={'tableWrapper'}>
                {dataModel ? (
                    <ListPage
                        title="Scoring"
                        dataModelCall={getDataModel}
                        triggerCall={triggerRefresh}
                        dataCall={(_dataModels) =>
                            getAllScoringsByModelId(
                                { modelId: dataModel },
                                _dataModels,
                            )
                        }
                        tableColumns={segmentTableColumn}
                        parameterPanel={FlowDetail}
                        HeaderComponent={() => (
                            <ScoreListHeader
                                dataModel={dataModel}
                                setDataModel={(a) => {
                                    setDataModel(a)
                                    setTriggerRefresh((v) => ++v)
                                }}
                                dataModels={dataModels}
                            />
                        )}
                    />
                ) : null}
            </div>
        </>
    )
}
