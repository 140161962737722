import React, { useEffect, useState } from 'react'
import ListPage from '../../Organisms/ListPage/ListPage'
import 'react-toastify/dist/ReactToastify.css'
import { getScriptsByDataModelId } from '../../../store/script/api'
import { getDataModel } from '../../../store/model/api'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { handleError } from '../../../utils/handleErrors'
import { formatDate } from '../../../Services/dateFormat'
import editGreen from '../../../imgs/EditGreen.png'
import Delete from '../../../imgs/recycle.png'
import View from '../../../imgs/view.png'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { FlowDetail } from '../FlowList/FlowDetail'
import { ScriptListHeader } from './ScriptListHeader'
import { archiveEntityById } from '../../../store/general'

export const ScriptList = () => {
    const [archivePopupId, setArchivePopupId] = useState(null)
    const [triggerRefresh, setTriggerRefresh] = useState(0)
    const [dataModel, setDataModel] = useState()
    const [dataModels, setDataModels] = useState([])
    const history = useHistory()

    useEffect(() => {
        getDataModel().then((_dataModels) => {
            setDataModels(_dataModels)
            setDataModel(_dataModels?.[0]?._id)
            setTriggerRefresh((v) => ++v)
        })
    }, [])

    const viewEntity = (item) => {
        history.push(`${window.location.pathname}/${item.data._id}?type=view`)
    }

    const deleteEntity = () => {
        archiveEntityById(archivePopupId)
            .then(() => {
                setArchivePopupId(null)
                setTriggerRefresh((v) => ++v)
                toast.success('Parameter deleted successfully')
            })
            .catch(handleError)
    }
    const scriptsTableColumn = [
        { field: 'name', filter: true, sort: true },
        {
            headerName: 'Data Model',
            field: 'dataModelName',
            filter: true,
            sort: true,
        },
        {
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            filter: true,
            sort: true,
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            alt={'edit'}
                            src={editGreen}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            alt={'Delete'}
                            src={Delete}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setArchivePopupId(params.data._id)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={View}
                            alt={'View'}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewEntity(params)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                )
            },
        },
    ]

    return (
        <>
            <ArchivePopup
                isOpen={!!archivePopupId}
                togglePanel={setArchivePopupId}
                onDelete={() => deleteEntity()}
            ></ArchivePopup>
            <div className={'tableWrapper'}>
                {dataModel ? (
                    <ListPage
                        pg="20"
                        triggerCall={triggerRefresh}
                        dataModelCall={getDataModel}
                        dataCall={() =>
                            getScriptsByDataModelId(
                                { modelId: dataModel },
                                dataModels,
                            )
                        }
                        tableColumns={scriptsTableColumn}
                        parameterPanel={FlowDetail}
                        HeaderComponent={() => (
                            <ScriptListHeader
                                dataModel={dataModel}
                                setDataModel={(a) => {
                                    setDataModel(a)
                                    setTriggerRefresh((v) => ++v)
                                }}
                                dataModels={dataModels}
                            />
                        )}
                    />
                ) : null}
            </div>
        </>
    )
}
