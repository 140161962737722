import React, { useCallback, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams } from 'react-router-dom'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import {
    getSegmentByDataModelId,
    getSegmentById,
} from '../../../store/segment/api'
import { handleError } from '../../../utils/handleErrors'
import { AddNewSegmentPopup } from '../../Organisms/Popups/AddNewSegmentPopup'
import { AddVariablePopup } from '../../Organisms/Popups/AddVariablePopup'
import { SegmentRemovePopup } from '../../Organisms/Popups/SegmentRemovePopup'
import { Segment } from './value-setter-segment'
import { getEntityVersionById, updateEntity } from '../../../store/common/api'
import { Header } from './header'

const entityType = 'valueSetter'
const entityURL = 'value-setters'

export const ValueSetter = () => {
    const { id } = useParams()
    const [segmentModelsList, setSegmentModelsList] = useState([])
    const [entity, setEntity] = useState({})
    const entityVariables = entity?.body?.variables || []
    let entityTree = entity?.body?.tree || null
    const setStateCurrentTree = (tree) => {
        setEntity({
            ...entity,
            body: {
                ...entity.body,
                tree,
            },
        })
    }
    const updateEntityVariables = (variables) => {
        setEntity({
            ...entity,
            body: {
                ...entity.body,
                variables,
            },
        })
    }
    const removeEntityVariable = (id) => {
        updateEntityVariables(entityVariables?.filter((i) => i.id !== id))
    }
    const addNewVariable = () => {
        updateEntityVariables([
            ...entityVariables,
            {
                id: Math.random() + new Date().getTime(),
                name: variable,
                comment,
            },
        ])
        setShowVariablePopup(false)
    }

    const openAddVariablePopup = () => {
        setShowVariablePopup(true)
    }
    const normalizeBodyTree = (entityTree) => {
        processEntityTree(entityTree).then((updatedEntityTree) => {
            entityTree = updatedEntityTree
            const result = walkTree(entityTree)
            setStateLevelLength(result?.length ? result.length : 0)
        })
    }

    useEffect(() => {
        if (id) {
            getEntityVersionById(id, entityType)
                .then((versions) => {
                    setEntity(versions[0])
                    normalizeBodyTree(versions[0].body.tree)

                    getSegmentByDataModelId(
                        {
                            page: 1,
                            limit: 10000,
                            modelId: versions[0].modelId,
                        },
                        [],
                    )
                        .then((segments) => {
                            const options = segments.map((segment) => {
                                return {
                                    name: segment.name,
                                    value: segment._id,
                                }
                            })
                            setSegmentModelsList(() => [
                                { name: 'Choose', value: '' },
                                ...options,
                            ])
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
    }, [id])

    const [showVariablePopup, setShowVariablePopup] = useState(false)
    const [variable, setVariable] = useState()
    const [comment, setComment] = useState()
    const [showSegmentPopup, setShowSegmentPopup] = useState(false)
    const [selectedSegment, setSelectedSegment] = useState()
    const [segmentArchivePopup, setSegmentArchivePopup] = useState(false)
    const [stateLevelLength, setStateLevelLength] = useState(0)
    const [selectedSegmentIds, setSelectedSegmentIds] = useState([])

    function walkTree(tree, level = 0, collection = [], segments = []) {
        if (!Array.isArray(collection[level])) {
            collection[level] = []
        }

        collection[level].push(tree.conditions)
        segments.push(tree.segmentId)
        tree?.conditions?.forEach((item) => {
            if (item.children) {
                collection = walkTree(
                    item.children[0],
                    level + 1,
                    collection,
                    segments,
                )
            }
        })
        setSelectedSegmentIds(segments)
        return collection
    }

    const processEntityTree = async (tree) => {
        const processConditions = async (conditions, segmentId) => {
            const { segment } = await getSegmentById({
                id: segmentId,
            })

            return await Promise.all(
                segment.conditions.map(async (c) => {
                    const existingCondition =
                        conditions.find((s) => s.id === c.id) || {}
                    if (
                        existingCondition.children &&
                        existingCondition.children.length
                    ) {
                        existingCondition.children[0].conditions =
                            await processConditions(
                                existingCondition.children[0].conditions,
                                existingCondition.children[0].segmentId,
                            )
                    }
                    return {
                        ...existingCondition,
                        ...c,
                    }
                }),
            )
        }

        // Start processing the top-level conditions in the tree
        if (tree && tree.length) {
            const updatedTree = await Promise.all(
                tree.map(async (node) => {
                    if (node.conditions && node.conditions.length) {
                        node.conditions = await processConditions(
                            node.conditions,
                            node.segmentId,
                        )
                    }
                    return node
                }),
            )
            return updatedTree
        }

        return tree
    }

    const updateVariables = (
        nodes,
        treeId,
        conditionId,
        variableName,
        value,
    ) => {
        return nodes.map((node) => {
            if (node.treeId === treeId) {
                return {
                    ...node,
                    conditions: node.conditions.map((condit) => {
                        if (condit.id === conditionId) {
                            condit[variableName] = value
                            return { ...condit }
                        }
                        if (condit.children) {
                            return {
                                ...condit,
                                children: updateVariables(
                                    condit.children,
                                    treeId,
                                    conditionId,
                                    variableName,
                                    value,
                                ),
                            }
                        }
                        return condit
                    }),
                }
            }

            return {
                ...node,
                conditions: node.conditions.map((condition) => {
                    if (condition.children) {
                        return {
                            ...condition,
                            children: updateVariables(
                                condition.children,
                                treeId,
                                conditionId,
                                variableName,
                                value,
                            ),
                        }
                    }
                    return condition
                }),
            }
        })
    }

    const updateVariable = (treeId, conditionId, variableName, value) => {
        setStateCurrentTree(
            updateVariables(
                entityTree,
                treeId,
                conditionId,
                variableName,
                value,
            ),
        )
    }

    const addSegmentChild = (treeId, conditionId, name) => {
        setShowSegmentPopup({ treeId, conditionId, name })
    }

    const setToggleSegment = (nodes, treeId, conditionId, isOpen) => {
        return nodes.map((node) => {
            if (node.treeId === treeId) {
                return {
                    ...node,
                    conditions: node.conditions.map((condition) => {
                        if (condition.id === conditionId) {
                            return {
                                ...condition,
                                isOpen: isOpen,
                            }
                        }
                        if (condition.children) {
                            return {
                                ...condition,
                                children: setToggleSegment(
                                    condition.children,
                                    treeId,
                                    conditionId,
                                    isOpen,
                                ),
                            }
                        }
                        return condition
                    }),
                }
            }

            return {
                ...node,
                conditions: node.conditions.map((condition) => {
                    if (condition.children) {
                        return {
                            ...condition,
                            children: setToggleSegment(
                                condition.children,
                                treeId,
                                conditionId,
                                isOpen,
                            ),
                        }
                    }
                    return condition
                }),
            }
        })
    }

    const toggleRow = (treeId, conditionId, isOpen) => {
        const obj = setToggleSegment(entityTree, treeId, conditionId, isOpen)
        setStateCurrentTree(obj)
    }

    const removeChildSegment = (parent, removedEl) => {
        return parent?.map((node) => {
            if (node.treeId === removedEl.treeId) {
                return {
                    ...node,
                    conditions: node.conditions.map((condit) => {
                        if (condit.id === removedEl.conditionId) {
                            delete condit.children
                            return condit
                        }
                        if (condit.children) {
                            return {
                                ...condit,
                                children: removeChildSegment(
                                    condit.children,
                                    segmentArchivePopup,
                                ),
                            }
                        }
                        return condit
                    }),
                }
            }

            return {
                ...node,
                conditions: node.conditions.map((condition) => {
                    if (condition.children) {
                        return {
                            ...condition,
                            children: removeChildSegment(
                                condition.children,
                                segmentArchivePopup,
                            ),
                        }
                    }
                    return condition
                }),
            }
        })
    }

    const onArchiveSegment = () => {
        setStateCurrentTree(removeChildSegment(entityTree, segmentArchivePopup))
        setSegmentArchivePopup(false)
    }

    const setChildSegment = (nodes, level, segment) => {
        return nodes.map((node) => {
            if (node.treeId === showSegmentPopup.treeId) {
                return {
                    ...node,
                    level,
                    conditions: node.conditions.map((condition) => {
                        if (condition.id === showSegmentPopup.conditionId) {
                            return {
                                ...condition,
                                isOpen: true,
                                level,
                                children: [
                                    {
                                        ...segment,
                                        level: level + 1,
                                        treeId:
                                            Math.random() +
                                            new Date().getTime(),
                                    },
                                ],
                            }
                        }
                        if (condition.children) {
                            return {
                                ...condition,
                                level,
                                children: setChildSegment(
                                    condition.children,
                                    level + 1,
                                    segment,
                                ),
                            }
                        }
                        return {
                            ...condition,
                            level,
                        }
                    }),
                }
            }

            return {
                ...node,
                level,
                conditions: node.conditions.map((condition) => {
                    if (condition.children) {
                        return {
                            ...condition,
                            level,
                            children: setChildSegment(
                                condition.children,
                                level + 1,
                                segment,
                            ),
                        }
                    }
                    return {
                        ...condition,
                        level,
                    }
                }),
            }
        })
    }

    const addSegment = useCallback(() => {
        getSegmentById({ id: selectedSegment })
            .then(({ segment }) => {
                const { segmentId, name, conditions } = segment
                const newSegment = { segmentId, name, conditions }
                const newTree = setChildSegment(entityTree, 1, newSegment)
                setStateCurrentTree(newTree)
                setShowSegmentPopup(false)
            })
            .catch(handleError)
    }, [showSegmentPopup, selectedSegment, selectedSegmentIds])

    const normalizeMainSegment = (action) => {
        return {
            ...action,
            name: entity?.name,
            body: {
                variables: [...entity.body.variables],
                tree: [
                    {
                        main: true,
                        name: action?.name,
                        conditions: action?.conditions,
                        segmentId: action?.segmentId,
                        treeId: Math.random() + new Date().getTime(),
                    },
                ],
            },
        }
    }

    const onChangeSegment = (id) => {
        if (id) {
            getSegmentById({ id })
                .then(({ segment }) => {
                    const normSeg = normalizeMainSegment(segment)
                    setEntity(normSeg)
                })
                .catch(handleError)
        }
    }

    return (
        <>
            <ToastContainer />
            {segmentArchivePopup ? (
                <SegmentRemovePopup
                    setSegmentArchivePopup={setSegmentArchivePopup}
                    segmentName={segmentArchivePopup.treeId}
                    onArchiveSegment={onArchiveSegment}
                />
            ) : null}
            {showSegmentPopup ? (
                <AddNewSegmentPopup
                    setShowSegmentPopup={setShowSegmentPopup}
                    segmentList={segmentModelsList}
                    addSegment={addSegment}
                    setSelectedSegment={setSelectedSegment}
                    selectedSegment={selectedSegment}
                />
            ) : null}

            {showVariablePopup ? (
                <AddVariablePopup
                    variable={variable}
                    setVariable={setVariable}
                    comment={comment}
                    setComment={setComment}
                    setShowVariablePopup={setShowVariablePopup}
                    addVariable={addNewVariable}
                />
            ) : null}
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="value-setter-content">
                        <div className="formHeaderActions">
                            <div className="formHeaderSelect">
                                {segmentModelsList?.length ? (
                                    <SelectWithLabelInput
                                        label="Select Segment"
                                        options={segmentModelsList}
                                        defaultValue={
                                            entityTree?.length
                                                ? entityTree[0]?.segmentId
                                                : ''
                                        }
                                        onChange={({ target }) => {
                                            onChangeSegment(target.value)
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="formHeaderButton">
                                <ButtonWithIcon
                                    onClick={openAddVariablePopup}
                                    icon="/assets/images/icon+.png"
                                    transparent={true}
                                    value="Add Variable"
                                />
                            </div>
                        </div>
                        <Segment
                            stateLevelLength={stateLevelLength}
                            updateVariable={updateVariable}
                            stateCurrentTree={
                                entityTree?.length ? entityTree[0] : null
                            }
                            stateCurrentVariables={entityVariables}
                            removeVariable={removeEntityVariable}
                            addSegmentChild={addSegmentChild}
                            toggleRow={toggleRow}
                            setSegmentArchivePopup={setSegmentArchivePopup}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
