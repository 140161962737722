import React, { useState } from 'react'
import MonacoEditor from '@monaco-editor/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { TextInput } from '../../Atoms/Inputs/Text'
import s from './createScript.module.scss'
const entityURL = 'scripts'

export const CreateScript = () => {
    const [entity, setEntity] = useState({
        name: '',
        body: null,
    })
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="script-content">
                        <TextInput
                            label="Name"
                            value={entity.name}
                            onChange={({ target }) => {
                                onEntityChange('name', target.value)
                            }}
                            placeholder="Name"
                        />
                        <MonacoEditor
                            className={s.monacoEditorWrapper}
                            defaultLanguage="javascript"
                            theme="vs-dark"
                            value={entity?.body}
                            onChange={(value) => {
                                onEntityChange('body', value)
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
