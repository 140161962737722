import React from 'react'
import MonacoEditor from '@monaco-editor/react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { handleError } from '../../../utils/handleErrors'
import { getEntityVersionById, updateEntity } from '../../../store/common/api'
import { Header } from './header'
import { SinglePageHook } from '../../../hooks/single-page-hook'
import s from './createScript.module.scss'
const entityType = 'script'
const entityURL = 'scripts'

export const Script = () => {
    const { entity, setEntity } = SinglePageHook({
        entityType,
    })
    const onEditorChange = (body) => {
        setEntity({
            ...entity,
            body,
        })
    }

    return (
        <>
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="script-content">
                        <MonacoEditor
                            className={s.monacoEditorWrapper}
                            defaultLanguage="javascript"
                            theme="vs-dark"
                            value={entity.body}
                            onChange={onEditorChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
